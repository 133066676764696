.background {
    background-image: url("../images/login-bg.gif");
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.outer {
    border-radius: 300px;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.container {
    border-radius: 10px;
    display: flex;
    height: 75vh;
    width: 80vw;
    background-color: #1e1f22;
    opacity: 0.8;
    justify-content: flex-start;
    flex-direction: column;
}

.logocontainer {
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amitylogo {
    margin-top: 30px;
    height: 10vh;
    margin-bottom: 30px;
    background-color: rgb(255, 255, 255, 0.8) ;
    border-radius: 5px;
}

.cyberlogo {
    height: 15%;
}

.form {
    display: flex;
    height: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.forminput {
    font-family: 'Lato', sans-serif;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    padding: 12px 20px;
    border: none;
    border-bottom: 20px;
}

.formbtn {
    font-family: 'Lato', sans-serif;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 8px 20px;
    background-color: rgb(240, 240, 10);
    color: rgb(34, 21, 21);
    transition: all 0.3s;
}

.formbtn:hover {
    transform:scale(1.2,1.2);
}

.formdisablebtn {
    font-family: 'Lato', sans-serif;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 8px 20px;
    background-color: rgb(83, 83, 61);
    color: rgb(34, 21, 21);
    cursor: not-allowed;
}

@media only screen and (min-width: 520px) {
    .cyberlogo {
        height: 20%;
    }

    .container {
        height: 75vh;
        width: 60vw;
    }    

    .forminput {
        width: 60%;
    }

    .amitylogo {
        margin: 30px 0;
    }
}

@media only screen and (min-width: 900px) {
    .container {
      width: 30vw;
    }
}

.spinner {
    display: flex;
    margin-top: 7vh;
    align-items: center;
    justify-content: center;
}