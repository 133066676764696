.nav {
    background-color: #263143;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
}

.cyberhacklogo {
    height: 7vh;
}
