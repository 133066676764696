.rank {
    background-color: #263143;
    color: white;
    align-self: center;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgb(177, 152, 12);
}

.info {
    font-size: 10px;
    color: rgb(252, 223, 145);
}

.mbs {
    margin-bottom: 5px;
}