.container {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid white;
}

.buttons {
    margin-top: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.question {
    padding: 20px;
    flex: 2;
    color: white;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.buttonscontainer {
    display: flex;
    align-items: center;
    margin: 10px 10px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #172130;
    transition: all;
}

.buttonscontainer:hover {
    transform: scale(1.05);
    background-color: #1e2a3b;
}

/* .buttonscontainer:hover .title {
    color: white; 
}
.buttonscontainer:hover .subtitle {
    color: white; 
}
.buttonscontainer:hover .icon {
    color: white; 
} */

.titlecontainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title {
    font-weight: 700;
    padding: 2px;
    color: white;
}

.subtitle {
    padding: 2px;
    color: white;
}

.icon {
    padding: 10px;
    color: white;
}

@media only screen and (max-width: 520px) {
    .container {
        flex-direction: column;
    }

    .buttons {
        flex-direction: row;
    }

    .buttonscontainer {
        flex-direction: column;
        text-align: center;
    }

    .subtitle {
        display: none;
    }
}

.info {
    font-size: 12px;
    color: rgb(252, 223, 145);
}
