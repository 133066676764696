.container {
    padding: 5px 10px;
    width: 100%;
    display: flex;
    color: white;
    justify-content: space-between;
}

.pointscontainer {
    display: flex;
    align-items: center;
}

.pointscontainer div {
    padding: 5px;
}

.innercontainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;

}

@media only screen and (max-width: 520px) {

    .innercontainer {
        justify-content: space-between;
        margin: 5px 0;
    }
}