.questions {
    width: 65vw;
    padding: 0 !important;
    background-color: #141d2b;
    margin-bottom: 7vh;
}

.question {
    padding: 0;
    background-color: #263143;
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
}

/* .question1 {
    padding: 0;
    background-image: linear-gradient(to bottom right, #26314371, #263143bb), url('../images/wind.png');
    background-size: cover;
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
}
.question2 {
    padding: 0;
    background-image: linear-gradient(to bottom right, #26314371, #263143bb), url('../images/water.png');
    background-size: cover;
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
    
}

.question3 {
    padding: 0;
    background-image: linear-gradient(to bottom right, #26314371, #263143bb), url('../images/ice.png');
    background-size: cover;
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
}

.question4 {
    padding: 0;
    background-image: linear-gradient(to bottom right, #26314371, #263143bb), url('../images/fire.png');
    background-size: cover;
    margin-bottom: 24px;
    overflow: hidden;
    border: 0px;
    border-radius: 2px;
} */


.questiontext {
    color: rgb(224, 224, 12);
}

@media only screen and (max-width: 520px) {
    .questions {
        width: 85vw;
    }
}