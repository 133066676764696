.outercontainer {
    width: 100%;
    height: 100%;
}

.container {
    height: 27vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #172130;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5vw;
}

.heading {
    color: rgb(221, 221, 21);
}

.whitetext {
    color: white;
}

.deduct {
    font-family: 'Dancing Script', cursive;
}

@media only screen and (max-width: 520px) {
    .container {
        width: 65vw;
        margin-left: 0;
    }
}